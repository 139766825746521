<template>
  <!-- FOOTER -->
<b-container fluid class="footer">
          <b-row align-h="end">
              <b-col cols="12" md="5" lg="4">
                <div class="header-big-right">
                  <div class="header-text">
                    <h2>{{ footer.items[0].header_footer }}</h2>
                    <h1>{{ footer.items[0].header_footer_big }}</h1>
                  </div>
                </div>
                <div class="text">
                  <ul>
                    <li><b-img src="../assets/phone-icon.svg"></b-img><b-link :href="`tel:${footer.items[0].footer_phone}`">{{ footer.items[0].footer_phone }}</b-link></li>
                    <li><b-img src="../assets/mail-icon.svg"></b-img><b-link :href="`mailto:${footer.items[0].footer_mail}`">{{ footer.items[0].footer_mail }}</b-link></li>
                    <li><b-img src="../assets/more-icon.svg"></b-img><b-link href="/contact">more</b-link></li>
                  </ul>
                </div>
                
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-img fluid src="../assets/contact-map.svg" class="map-img" @click="openUrl('/contact/')"></b-img>
                <!-- <b-img fluid src="../assets/map.png" class="map-img" @click="openUrl('/contact/')"></b-img> -->
              </b-col>
          </b-row>

          <b-row align-h="between" class="footer-down">
              <b-col cols="4" md="5" lg="4" class="copy-mobile">
                <div class="copy">© Panthalas 2021</div>
              </b-col>
              <b-col cols="4" md="7" lg="4" class="footer-menu">
                <b-nav>
                  <b-nav-item to="/">Home</b-nav-item>
                  <b-nav-item to="/about">About Us</b-nav-item>
                  <b-nav-item to="/services">Services</b-nav-item>
                  <b-nav-item to="/contact">Contact</b-nav-item>
                </b-nav>
              </b-col>
          </b-row>
              
</b-container>
<!-- END FOOTER -->
</template>

<script>
import axios from 'axios'
export default {
    name: 'Footer',
    data() {
      return {
        footer: [],
        errors: [],
      }
    },
    created () {
      this.getFooter()
    },
    methods: {
      openUrl(url) {
        this.$router.push({ path: url })
      },
      getFooter() {
        // this.url = 'http://localhost:8000/api/v2/pages/?type=contact.ContactIndex&fields=*'
        this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=contact.ContactIndex&fields=*'
        axios({
          method: 'get',
          url: this.url,
          headers: {
            Authorization: 'Token '+ this.mainToken
            }
        }).then(response => {
          this.footer = response.data
          // this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
      },
    },
}
</script>

<style>
/* footer */
.footer {
  margin-top: 70px;
  padding-top: 50px;
  position: relative;
  height: 100%;
  background-color: #96ACDB;
  overflow: hidden;
  z-index: 999;
}
.footer .header-big-right .header-text h1 {
  color: #B5C4E5;
}
.footer .header-big-right .header-text h1::before {
  content: url(../assets/header-icons-footer.svg);
}
.footer .text {
  margin-top: 60px;
}
.footer .map-img {
  padding-left: 20px;
  /* max-width: 600px; */
}
.footer .map-img:hover {
  cursor: pointer;
  opacity: 0.90;
}
.footer ul {
  list-style: none;
  padding-left: 0;
}
.footer ul li {
  padding-bottom: 16px;
}
.footer ul li img{
  padding-right: 16px;
  
}
.footer ul li a{
  color: #fefefe;
}
.footer ul li a:hover{
  color: #DEE4F3;
}
.footer .footer-down {
  margin-top: 40px;
  background-color: #3C6382;
}
.footer .footer-down .copy {
  color: #fefefe;
  margin-top: 16px;
  /* padding-left: 15%; */
  text-align: center;
}
.footer .footer-down .footer-menu {
  margin-top: 8px;
}
.footer .footer-down .footer-menu a.nav-link {
  font-weight: 500;
}

/* Responsive */

@media (min-width: 600px) and (max-width: 959px) {
  .footer .footer-down .copy {
    padding-left: 16px;
    text-align: left;
  }
}
@media (max-width: 600px) {
  .footer {
    margin-top: 50px;
  } 
  .footer ul li {
    padding-bottom: 0;
  }
  .footer .copy-mobile {
    align-self: flex-end !important;
  }
}
</style>