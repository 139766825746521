<template>
<div>
  <!-- BANNER -->
  <div class="banner" v-if="loading">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="11" lg="11">
                <div class="d-flex justify-content-center spinner-banner">
                  <b-spinner label="Loading..." variant="primary"></b-spinner>
                </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else class="banner">
    <div class="video-bg">
    <!-- <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
      <source src="../assets/home.mp4" type="video/mp4">
    </video> -->
    
    <b-container fluid class="banner-text">
            <b-row align-h="end">    
              <b-col cols="12" md="11" lg="11">
                <h1>{{ home.items[0].banner_header }}</h1>
                <p>{{ home.items[0].banner_caption }}</p>
              </b-col>
          </b-row>
    </b-container>

    <b-container fluid class="banner-anchor fadeInAnchor">
          <b-row class="text-center" align-v="center">
              <b-col>
                <b-img src="../assets/anchor.svg" class="anchor" v-scroll-to="'#main-content'"></b-img>
              </b-col>
          </b-row>
    </b-container>
  </div>

</div>
  <!-- END BANNER -->

<!-- ABOUT -->
<b-container fluid class="about" id="main-content">
          <b-row align-h="end">
              <b-col cols="12" md="5" lg="4">
                <div class="header-big-right">
                  <div class="header-text">
                    <h2>{{ home.items[0].header_about }}</h2>
                    <h1>{{ home.items[0].header_about_big }}</h1>
                  </div>
                </div>
                <div class="text">
                  <div v-html="home.items[0].about_info"></div>

                  <div class="more">
                    <b-link to="/about">MORE</b-link>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-img :src="'https://panthalasback.flexisoft.usermd.net' + about.items[0].about_image.url" fluid :alt="about.items[0].about_image.alt"></b-img>
              </b-col>
          </b-row>
</b-container>
<!-- END ABOUT -->

<!-- SERVICES -->
<b-container fluid class="services">
          <b-row align-h="end">
              <b-col cols="12" md="6" lg="6">
                <div class="header-big-left">
                  <div class="header-text">
                    <h2 style="color: #eee">{{ home.items[0].header_services }}</h2>
                    <h1>{{ home.items[0].header_services_big }}</h1>
                  </div>
                </div>
                <div class="text">
                  <div v-html="home.items[0].service_info"></div>

                  <b-row>
                    
                    <b-col v-for="service in services.items" :key="service.id" cols="12" md="6" class="service-card" @click="openUrl('/commodities/' + service.id + '/' + service.meta.slug )">
                      <b-card
                          overlay
                          :img-src="'https://panthalasback.flexisoft.usermd.net' + service.thumbnail_main.url"
                          :img-alt="service.image.title"
                          text-variant="white"
                        >
                          <b-card-text>
                            {{ service.title }}
                          </b-card-text>
                        </b-card>
                    </b-col>
                  
                  </b-row> 
                
                  <div class="text-right">
                    <b-button variant="primary" @click="openUrl('/commodities/')">Commodities</b-button>
                  </div> 
                
                  
                </div>
              </b-col>
              <b-col cols="12" md="5" lg="4">
              </b-col>
          </b-row>
</b-container>
<!-- END SERVICES -->

<!-- REVIEWS -->
<b-container fluid class="reviews">
          <b-row align-h="center">
              <b-col cols="12" md="10" lg="8">
                <div class="header-big-left">
                  <div class="header-text">
                    <h2>{{ home.items[0].header_ref }}</h2>
                    <h1>{{ home.items[0].header_ref_big }}</h1>
                  </div>
                </div>
                
                <b-carousel v-if="!isMobile() || isIpadOS()"
                  id="carousel-fade"
                  fade
                  indicators
                  img-width="1024"
                  img-height="200"
                >
                  <b-carousel-slide img-blank img-alt="Blank image" v-for="ref in references.items" :key="ref.id">
                    <div v-html="ref.reference"></div>
                    <div class="client">
                      <span>{{ ref.author }}</span>
                    </div>
                  </b-carousel-slide>
                  
                </b-carousel>
                <!-- mobile -->
                <b-carousel v-else
                  id="carousel-fade"
                  fade
                  indicators
                  img-width="800"
                  img-height="600"
                >
                  
                  <b-carousel-slide img-blank img-alt="Blank image" v-for="ref in references.items" :key="ref.id">
                    <div v-html="ref.reference"></div>
                    <div class="client">
                      <span>{{ ref.author }}</span>
                    </div>
                  </b-carousel-slide>
                  
                </b-carousel>
              </b-col>
          </b-row>
</b-container>
<!-- END REVIEWS -->




</div>
</template>

<script>
import axios from 'axios'
import isMobile from '@/mixins/mobileCheck';
import isIpadOS from '@/mixins/mobileCheck';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Home',
  mixins: [isMobile, isIpadOS],
  components: {
    // HelloWorld
  },
  data() {
    return {
      home: [],
      about: [],
      services: [],
      references: [],
      errors: [],
      loading: true,
    }
  },
  created () {
    this.getHome()
    this.getAbout()
    this.getServices()
    this.getReferences()
  },
  methods: {
    openUrl(url) {
      this.$router.push({ path: url })
    },
    getHome() {
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=main.MainPage&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
        }).then(response => {
          this.home = response.data
          this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
    },
    getAbout() {
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=about.AboutPage&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
        }).then(response => {
          this.about = response.data
          // this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
    },
    getServices() {
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=services.ServicePage&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
        }).then(response => {
          this.services = response.data
          // this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
    },
    getReferences() {
      this.url = 'https://panthalasback.flexisoft.usermd.net/api/v2/pages/?type=main.ReferencePage&fields=*'
      axios({
        method: 'get',
        url: this.url,
        headers: {
          Authorization: 'Token '+ this.mainToken
          }
        }).then(response => {
          this.references = response.data
          // this.loading = false
        }).catch(e => {
          this.errors.push(e)
        })
    },
  }
  
}
</script>
<style lang="css">
.banner {
  background-image: url(../assets/bg1.jpg);
  background-size: cover;
}


/* about */
.about {
  margin-top: 70px;
}
.about .text {
  margin-top: 50px;
}
.about .more {
  text-align: right;
}
/* services */
.services {
  margin-top: 70px;
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
  height: 100%;
  /* background-color: rgba(28, 28, 30, .9); */
  overflow: hidden;
}
.services .text {
  margin-top: 50px;
}
.services .header-text h1 {
  color: #B6C4D5;
}
.services:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -999;
    /* opacity: 0.1; */
    background-image: url('../assets/bg-image1.jpg');
    background-repeat: no-repeat;
    /* background-position: 50% 0; */
    background-position: center;
    background-size: cover;
}
.services {
  position: relative;
  z-index: 1;
}
.service-card {
  margin-bottom: 16px;
}
.service-card:hover {
  cursor: pointer;
  opacity: 0.9;
}

/* reviews */
.reviews {
  margin-top: 70px;
}
.reviews .header-big-left .header-text h2 {
  padding-left: 12%;
}
.reviews .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: 0;
    z-index: 10;
    padding-top: 50px;
    padding-bottom: 20px;
    color:#263238;
    text-align: left;
}
.reviews .carousel-caption p {
    font-weight: lighter;
    margin-bottom: 0.5rem;
}
.reviews .client {
  text-align: right;
  color: #607D8B;
  font-weight: 500;
}
.reviews .carousel-indicators li {
  background-color: #D8D8D8;
}
/* Responsive */

@media (min-width: 600px) and (max-width: 959px) {
  .about {
    margin-top: 50px;
  }
  .about .text {
    margin-top: 40px;
  }
  .about .more {
    padding-bottom: 16px;
  }
  .services {
    margin-top: 50px;
  }
  .services .text {
    margin-top: 30px;
  }
  .reviews {
    margin-top: 50px;
  }
  .reviews .header-big-left .header-text h2 {
    padding-left: 12%;
  }
  .reviews .carousel-caption {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .about {
    margin-top: 50px;
  }
  .about .text {
    margin-top: 30px;
  }
  .about .more {
    padding-bottom: 16px;
  }
  .services {
    margin-top: 50px;
  }
  .services .text {
    margin-top: 30px;
  }
  .reviews {
    margin-top: 50px;
  }
  .reviews .header-big-left .header-text h2 {
    padding-left: 12%;
  }
  .reviews .carousel-caption {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

</style>

