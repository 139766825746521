<template>
  <div id="app">
    <Header/>
    
    <router-view/>

    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800&display=swap');


html, body {
  font-family: 'Roboto Slab', serif;
}
#app {
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #263238;
}
a {
  color: #60A3BC;
}
a:hover {
  color: #3C6382;
  text-decoration: none;
}
#nav a {
  color: #fff;
}

#nav a.router-link-exact-active {
  color: #eee;
}
.btn {
  padding: .275rem 2rem;
  text-transform: uppercase;
  font-size: 15px;
}
.btn-primary {
    color: #fff;
    background-color: #6A89CC;
    border-color: #6A89CC;
}
.btn-primary:hover {
  background-color: #3C6382;
}

.card { 
    border: 0;
    border-radius: 0;
}
.card-img {
  border: 0;
  border-radius: 0;
}
.card-img-overlay {
    position: absolute;
    text-align: center;
    right: 0;
    top:auto;
    bottom: 0;
    left: 0;
    padding: .75rem;
    border-radius: calc(.25rem - 1px);
    background-color: rgba(4, 4, 4, .3);
}

.banner {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  
}
.banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #263238;
  opacity: .5;
}
.banner .video-bg video {
  /* width: 100%;
  height: 100vh;
  background-size: cover; */
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.banner .banner-text {
  position: absolute;
  bottom: 160px;
  color: #fff;
  
}
.banner .banner-text h5 {
  color: #7F8FA6;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}
.banner .banner-text h1 {
  font-size: 80px;
  margin-bottom: 8px;
  line-height: 1;
  text-transform: uppercase;
}
.banner .banner-text p {
  /* font-size: 14px;
  color: #E5E5E5; */
  color: #7F8FA6;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 0;
}

.banner .banner-anchor {
  position: absolute;
  bottom: 70px;
}
.banner .banner-anchor img {
  height: 50px;
}
.spinner-banner {
  margin-top: 400px;
}

.fadeInAnchor {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeIn {
  0% {
  opacity: 0;
  /* -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); */
  -webkit-transform: translate3d(0, 0, 100%,);
  transform: translate3d(0, 0, 100%);
  }
  100% {
  opacity: 1;
  -webkit-transform: translate3d(0, 15%, 0);
  transform: translate3d(0, 15%, 0);
  }
  }
  @keyframes fadeIn {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 100%,);
  transform: translate3d(0, 0, 100%);
  }
  100% {
  opacity: 1;
  -webkit-transform: translate3d(0, 15%, 0);
  transform: translate3d(0, 15%, 0);
  }
}
.anchor:hover {
  cursor: pointer;
  opacity: 0.9;
}

/* headers */
.header-big-right {
  margin-top: 16px;
  position: relative;
}
.header-big-right .header-text h1 {
  font-size: 140px;
  font-weight: lighter;
  color: #D8DDE4; 
  position: absolute;
  top: 35%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -999;
  text-transform: uppercase;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.header-big-right .header-text h2 {
  color: #3C6382;
  font-size: 50px;
  font-weight: bold;
  z-index: 999;
  text-transform: uppercase;
}
.header-big-right .header-text h1::before {
  display: inline-block;
  width: 80px;
  content: url(./assets/header-icons.svg);
  margin-top: 3px;
  margin-right: 1%; 
}
/* header left */
.header-big-left {
  margin-top: 16px;
  position: relative;
}
.header-big-left .header-text h1 {
  font-size: 140px;
  font-weight: lighter;
  color: #D8DDE4; 
  position: absolute;
  top: 35%;
  left: 49%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -999;
  text-transform: uppercase;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.header-big-left .header-text h2 {
  padding-left: 20%;
  color: #3C6382;
  font-size: 50px;
  font-weight: bold;
  z-index: 999;
  text-transform: uppercase;
}
.header-big-left .header-text h1::after {
  display: inline-block;
  width: 80px;
  content: url(./assets/header-icons.svg);
  margin-top: 3px;
  padding-left: 1%;
}

/* Responsive */

@media (min-width: 600px) and (max-width: 959px) {
  body {
    font-size: 15px;
  }
  .banner .banner-text {
    bottom: 160px;
  }
  .banner .banner-text h5 {
    font-size: 13px;
  }
  .banner .banner-text h1 {
    font-size: 54px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .banner .banner-text p {
    font-size: 14px;
  }
  .banner .banner-anchor {
    position: absolute;
    bottom: 40px;
  }
  .banner .banner-anchor img {
    height: 40px;
  }

  .header-big-right {
    margin-top: 0px;
  }
  .header-big-right .header-text h1 {
    font-size: 90px;
  }
  .header-big-right .header-text h2 {
    font-size: 34px;
  }
  .header-big-right .header-text h1::before {
    margin-top: 8px;
  }

  .header-big-left {
    margin-top: 0x;
  }
  .header-big-left .header-text h1 {
    font-size: 90px;
  }
  .header-big-left .header-text h2 {
    font-size: 34px;
  }
  .header-big-left .header-text h1::after {
    margin-top: 6px;
  }
}
@media (max-width: 600px) {
  body {
    font-size: 15px;
  }
  .banner .banner-text {
    bottom: 140px;
  }
  .banner .banner-text h5 {
    font-size: 13px;
  }
  .banner .banner-text h1 {
    font-size: 48px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  .banner .banner-text p {
    font-size: 14px;
  }
  .banner .banner-anchor {
    position: absolute;
    bottom: 40px;
  }
  .banner .banner-anchor img {
    height: 40px;
  }

  .header-big-right {
    margin-top: 0px;
  }
  .header-big-right .header-text h1 {
    font-size: 90px;
  }
  .header-big-right .header-text h2 {
    font-size: 34px;
  }
  .header-big-right .header-text h1::before {
    margin-top: 8px;
  }

  .header-big-left {
    margin-top: 0x;
  }
  .header-big-left .header-text h1 {
    font-size: 90px;
  }
  .header-big-left .header-text h2 {
    font-size: 34px;
  }
  .header-big-left .header-text h1::after {
    margin-top: 6px;
  }

}

</style>
