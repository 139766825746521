<template>
  <div id="nav">

      <b-navbar fixed="top" toggleable type="dark" variant="info" :class="{ 'navbar--hidden': !showNavbar }">
        <b-navbar-brand to="/"><b-img :src="logo" class="logo"></b-img></b-navbar-brand>

        <b-navbar-toggle v-b-toggle.sidebar-variant>
            
        </b-navbar-toggle>


          <b-collapse id="nav-collapse right" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/">Home</b-nav-item>
                <b-nav-item to="/about">About</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>

    <div>

    <b-sidebar id="sidebar-variant" title="" bg-variant="dark" text-variant="light" right shadow>
      <div class="px-3 py-2 pt-3">
        <b-navbar-nav class="text-center mobile-nav">
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/about">About</b-nav-item>
          <b-nav-item to="/commodities">Commodities</b-nav-item>
          <b-nav-item to="/contact">Contact</b-nav-item>
          
        </b-navbar-nav>
      </div>
    </b-sidebar>
    </div>

  </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
      return {
        showNavbar: true,
        lastScrollPosition: 0,
        logo: require('@/assets/logo-lg.svg'),
      }
    },
    mounted () {
      window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      onScroll () {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollPosition < 0) {
          return
        }
        // else if (currentScrollPosition > 40) {
        //   this.showNavbar = true
        // }
        // Stop executing this function if the difference between
        // current scroll position and last scroll position is less than some offset
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 70) {
          return
        }
        this.showNavbar = currentScrollPosition < 100//this.lastScrollPosition
        
        this.lastScrollPosition = currentScrollPosition
        if (this.showNavbar == true) {
          this.logo = require('@/assets/logo-lg.svg')
        } else {
          this.logo = require('@/assets/logo-sm.svg')
        }
      }
    }
}

</script>

<style>

.navbar {
  z-index: 999;
  /* box-shadow: none; */
  /* transform: translate3d(0, -100%, 0); */
  /* bylo */
  /* background-color: #263238 !important; */
  background-color: rgba(38, 50, 56, 0.4) !important;
  transition: .3s all ease-in-out;
  transform: translate3d(0, -5%, 0);
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* opacity: .40; */
}
.navbar-nav {
  margin-top: 30px;
}
.navbar-dark
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1);
}
.navbar-dark .navbar-toggler {
  border: 0;
  padding: .25rem 0rem;
  font-size: 1.15rem;
  color: rgba(255,255,255,1) !important;
  border-color: rgb(255,102,203);
  padding-right: 16px;
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-brand {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 16px;
}
/* .logo {
    height: 16px;
} */
.logo {
  transition: .5s all ease-in-out;
  transform: translate3d(0, -5%, 0);
  padding-top: 8px;
  height: 60px;
}
.navbar.navbar--hidden {
  /* box-shadow: none; */
  /* transform: translate3d(0, -100%, 0); */
  /* bylo */
  /* background-color: #353537 !important; */
  background-color: rgba(38, 50, 56, 0.4) !important;
  transition: .5s all ease-in-out;
  transform: translate3d(0, -5%, 0);
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
  /* opacity: .96; */
}
.navbar.navbar--hidden .logo {
  transition: .3s all ease-in-out;
  transform: translate3d(0, -5%, 0);
  height: 42px;
}
/* sidebar */
.bg-dark {
    background-color: #96ACDB !important;
}
.b-sidebar-body .nav-item {
    color: #fff;
    font-size: 36px;
    text-transform: uppercase;
}
.b-sidebar > .b-sidebar-header .close {
    float: none;
    font-size: 4rem;   
}
/* Responsive */

@media (min-width: 600px) and (max-width: 959px) {
  .b-sidebar-body .nav-item {
    font-size: 28px;
  }
  .b-sidebar > .b-sidebar-header .close {
    font-size: 3rem; 
  }
}
@media (max-width: 600px) {
  .logo {
    transition: .5s all ease-in-out;
    transform: translate3d(0, -5%, 0);
    padding-top: 8px;
    height: 50px;
  } 
  .navbar-brand {
    padding-left: 0px;
  }
  .b-sidebar-body .nav-item {
    font-size: 28px;
  }
  .b-sidebar > .b-sidebar-header .close {
    font-size: 3rem; 
  }
}
</style>